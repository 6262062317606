* {
    box-sizing: border-box;
}

.map-container {
	position: absolute;
	top: 40px;
	bottom: 0px;
	left: 0px;
	right: 0px;
}